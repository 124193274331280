'use client';

import { faCircleDown, faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { toPng } from 'html-to-image';
import React, { forwardRef, useRef, useState } from 'react';

import { MatchV3 } from '@/components/MatchCardV3/types';

function truncateNames(firstName: string, lastName: string, maxLength: number = 19) {
	const truncationIndicator = '..';
	let combinedName = firstName + ' ' + lastName;

	// If the combined length is less than or equal to maxLength, return it as is
	if (combinedName.length <= maxLength) {
		return combinedName;
	}

	// Start truncating firstName
	for (let i = firstName.length; i > 0; i--) {
		firstName = firstName.substring(0, i);
		combinedName = `${firstName}${truncationIndicator}` + ' ' + lastName;
		if (combinedName.length <= maxLength) {
			return combinedName;
		}
	}

	// If firstName is reduced to 1 character and still exceeds maxLength, start truncating lastName
	for (let j = lastName.length; j > 0; j--) {
		lastName = lastName.substring(0, j);
		combinedName = firstName + ' ' + `${lastName}${truncationIndicator}`;
		if (combinedName.length <= maxLength) {
			return combinedName;
		}
	}

	// As a fallback, return the truncated combined name to maxLength
	return combinedName.substring(0, maxLength);
}

function truncateNamesSeparately(firstName: string, lastName: string, maxLength: number = 10) {
	const truncationIndicator = '..';

	function truncateName(name: string, maxLength: number): string {
		if (name.length <= maxLength) {
			return name;
		}

		for (let i = name.length; i > 0; i--) {
			const truncatedName = name.substring(0, i) + truncationIndicator;
			if (truncatedName.length <= maxLength) {
				return truncatedName;
			}
		}

		return name.substring(0, maxLength); // Fallback
	}

	const truncatedFirstName = truncateName(firstName, maxLength);
	const truncatedLastName = truncateName(lastName, maxLength);

	return {
		truncatedFirstName: truncatedFirstName,
		truncatedLastName: truncatedLastName
	};
}

const PickleballLogo = () => {
	return (
		<svg width="32" height="23" viewBox="0 0 32 23" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M25.8745 13.7314H16.4429L19.9579 2.0143C20.2394 1.07725 21.1016 0.435486 22.08 0.435486H31.5116L27.9967 12.1526C27.7151 13.0897 26.8529 13.7314 25.8745 13.7314Z"
				fill="white"
			/>
			<path
				d="M9.91899 22.596H0.487427L6.66194 2.01528C6.94345 1.07823 7.8057 0.436462 8.7841 0.436462H18.2157L12.0411 21.0172C11.7596 21.9543 10.8974 22.596 9.91899 22.596Z"
				fill="white"
			/>
		</svg>
	);
};

const MainScoreDivider = () => {
	return (
		<svg width="2" height="28" viewBox="0 0 2 28" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect y="0.5" width="2" height="27" rx="1" fill="url(#paint0_linear_2463_3831)" />
			<defs>
				<linearGradient id="paint0_linear_2463_3831" x1="0" y1="0.5" x2="2" y2="0.5" gradientUnits="userSpaceOnUse">
					<stop stopColor="#475467" />
					<stop offset="1" stopColor="#667085" />
				</linearGradient>
			</defs>
		</svg>
	);
};

const MainScoreSmallDivider = () => {
	return (
		<svg width="2" height="18" viewBox="0 0 2 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="2" height="18" rx="1" fill="url(#paint0_linear_2227_2867)" />
			<defs>
				<linearGradient id="paint0_linear_2227_2867" x1="0" y1="0" x2="2" y2="0" gradientUnits="userSpaceOnUse">
					<stop stopColor="#475467" />
					<stop offset="1" stopColor="#667085" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export interface CardHeaderProps {
	match: MatchV3;
	divRef: React.MutableRefObject<any>;
}

const CardHeader = ({ match, divRef }: CardHeaderProps) => {
	const [hideDownload, setHideDownload] = useState(false);
	const cardHeaderContainerClasses = clsx('relative grid px-3 pb-1 pt-2.5', {
		'grid-cols-[minmax(0,1fr)_calc(100%-120px)_minmax(0,1fr)]': match.matchStatus !== 2 || !match.courtName,
		'grid-cols-[minmax(0,1fr)_calc(100%-150px)_minmax(0,1fr)]': match.matchStatus === 2 && match.courtName
	});

	const downloadImage = async () => {
		const options = { style: { fontFamily: 'Inter, sans-serif' } };
		const dataUrl = await toPng(divRef.current, options);
		const blob = await fetch(dataUrl).then((res) => res.blob());
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.download = `${match.tournamentTitle}${match.courtName ? ` - ${match.courtName}` : ''}.png`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		window.URL.revokeObjectURL(url);
	};

	return (
		<div className={cardHeaderContainerClasses}>
			<span className="truncate text-left">
				<PickleballLogo />
			</span>
			<span className="line-clamp-2 whitespace-normal break-words text-center text-xs font-normal not-italic leading-[18px] text-white sm:text-sm sm:font-medium sm:leading-5">
				{match.tournamentTitle}
			</span>
			<span className="h-6 truncate text-right text-xs font-medium leading-[18px] text-white sm:inline">
				{match.matchStatus === 2 && `${match.courtName}`}
				{!hideDownload && (
					<div className="flex justify-end gap-2">
						<FontAwesomeIcon
							icon={faCircleDown}
							className="hover:text-gray-300"
							size="2xl"
							onClick={async (e) => {
								e.preventDefault();
								setHideDownload(true);
								await downloadImage();
								setHideDownload(false);
							}}
						/>
					</div>
				)}
			</span>
		</div>
	);
};

export interface TotalScoresProps {
	match: MatchV3;
}

const TotalScores = ({ match }: TotalScoresProps) => {
	let team1TotalScore = 0;
	let team2TotalScore = 0;

	match.team1.scores.forEach((team1Score, index) => {
		const team2Score = match.team2.scores[index] || 0;
		if ((team1Score >= 11 || team2Score >= 11) && Math.abs(team1Score - team2Score) >= 2) {
			if (team1Score > team2Score) {
				team1TotalScore++;
			} else if (team1Score < team2Score) {
				team2TotalScore++;
			}
		}
	});

	return (
		<div className="flex flex-row items-center justify-center gap-1 sm:gap-2">
			<div className="flex flex-col items-center">
				<span
					className={`w-5 text-center text-2xl font-medium not-italic leading-8 sm:w-8 sm:text-[36px] sm:leading-[44px] ${match.team1.isWinner ? 'text-green-300' : 'text-white'}`}
				>
					{team1TotalScore}
				</span>
			</div>
			<div className="flex flex-col items-center">
				<span className="hidden sm:inline">
					<MainScoreDivider />
				</span>
				<span className="inline sm:hidden">
					<MainScoreSmallDivider />
				</span>
			</div>
			<div className="flex flex-col items-center">
				<span
					className={`w-5 text-center text-2xl font-medium not-italic leading-8 sm:w-8 sm:text-[36px] sm:leading-[44px] ${match.team2.isWinner ? 'text-green-300' : 'text-white'}`}
				>
					{team2TotalScore}
				</span>
			</div>
		</div>
	);
};

export interface TeamScoresProps {
	match: MatchV3;
	teamKey: TeamKey;
}

const TeamScores = ({ match, teamKey }: TeamScoresProps) => {
	const oppositeTeamKey = teamKey === 'team1' ? 'team2' : 'team1';

	return (
		<div className="flex w-5 flex-col items-center gap-0.5 sm:gap-1">
			{match[teamKey].scores.map((score, index) => {
				const oppositeTeamScore = match[oppositeTeamKey].scores[index] || 0;
				return (
					<span
						key={index}
						className={`text-sm font-bold not-italic leading-5 ${match.matchStatus === 4 && score > oppositeTeamScore ? 'text-green-300' : 'text-white'}`}
					>
						{!score && !oppositeTeamScore && match.matchStatus !== 2 ? '-' : score}
					</span>
				);
			})}
		</div>
	);
};

export interface ScoresProps {
	match: MatchV3;
}

const Scores = ({ match }: ScoresProps) => {
	const didFFOrRET = match.gamesStatus && match.gamesStatus.some((gameStatus) => [2, 3, 7, 8].includes(gameStatus));
	return (
		<div className="flex flex-row justify-center gap-0.5 sm:gap-1.5">
			{didFFOrRET && (
				<div className="flex w-[23px] flex-col gap-0.5 sm:w-7 sm:gap-1">
					{match.gamesStatus?.map((gameStatus, index) => {
						return (
							<span key={index} className="h-5 text-xs font-medium not-italic leading-5 text-white sm:text-sm sm:font-semibold">
								{gameStatus === 2 ? 'FF' : gameStatus === 3 ? 'RET' : ''}
							</span>
						);
					})}
				</div>
			)}
			<TeamScores match={match} teamKey="team1" />
			<div className="flex flex-col gap-0.5 sm:gap-1">
				{match.team1.scores.map((_score, index) => {
					return (
						<span key={index} className="text-sm font-normal not-italic leading-5 text-white">
							-
						</span>
					);
				})}
			</div>
			<TeamScores match={match} teamKey="team2" />
			{didFFOrRET && (
				<div className="flex w-[23px] flex-col gap-0.5 sm:w-7 sm:gap-1">
					{match.gamesStatus?.map((gameStatus, index) => {
						return (
							<span key={index} className="h-5 text-xs font-medium not-italic leading-5 text-white sm:text-sm sm:font-semibold">
								{gameStatus === 7 ? 'FF' : gameStatus === 8 ? 'RET' : ''}
							</span>
						);
					})}
				</div>
			)}
		</div>
	);
};

type TeamKey = 'team1' | 'team2';

export interface TeamInfoProps {
	match: MatchV3;
	teamKey: TeamKey;
}

const TeamInfo = ({ match, teamKey }: TeamInfoProps) => {
	const bestOf5 = match[teamKey].scores.length >= 4;
	const isWinner = match[teamKey].isWinner;
	const oppositeTeamKey = teamKey === 'team1' ? 'team2' : 'team1';
	const singles = match[teamKey].players.length === 1;
	const teamWithdrew = match.matchStatus === 4 && match.matchCompletedType === 4 && !match[teamKey].isWinner;
	const oppositeTeamWithdrew = match.matchStatus === 4 && match.matchCompletedType === 4 && !match[oppositeTeamKey].isWinner;

	const nameClassesSingles = clsx('line-clamp-1 text-xs font-semibold leading-[18px] text-white sm:text-sm sm:leading-5');
	const nameClassesDoubles = clsx('text-xs font-semibold leading-[18px] text-white sm:text-sm sm:leading-5');
	const playerOneIsServer =
		teamKey === 'team1' ? match.server === 1 && match.serverFromTeam === 1 : match.server === 2 && match.serverFromTeam === 1;
	const playerTwoIsServer =
		teamKey === 'team1' ? match.server === 1 && match.serverFromTeam === 2 : match.server === 2 && match.serverFromTeam === 2;
	const secondServerDot =
		teamKey === 'team1' ? match.server === 1 && match.currentServingNumber === 2 : match.server === 2 && match.currentServingNumber === 2;

	return (
		<div className={`flex w-full flex-col items-center ${bestOf5 ? 'justify-center' : ''}`}>
			{teamWithdrew && <span className="text-xs font-bold leading-[18px] text-white">Withdrawn</span>}
			{oppositeTeamWithdrew && <span className="h-[18px] text-xs font-bold leading-[18px] text-white"></span>}
			<div className={`flex flex-row ${teamWithdrew ? 'pt-1 sm:pt-2' : 'pt-2 sm:pt-4'} ${singles ? 'w-full gap-0' : 'gap-0.5 sm:gap-1.5'}`}>
				{match[teamKey].players.map((player, index) => {
					const singlesNamesTruncated = truncateNames(player.firstName, player.lastName, 19);
					const singlesNamesTruncatedSM = truncateNames(player.firstName, player.lastName, 23);

					const { truncatedFirstName, truncatedLastName } = truncateNamesSeparately(player.firstName, player.lastName, 9);
					const { truncatedFirstName: truncatedFirstNameSM, truncatedLastName: truncatedLastNameSM } = truncateNamesSeparately(
						player.firstName,
						player.lastName,
						11
					);

					return (
						<div
							key={player.playerId}
							className={`flex flex-col items-center gap-1 sm:gap-1.5 ${singles ? 'w-full' : 'w-[65px] sm:w-[100px]'}`}
						>
							{player.image ? (
								<img
									src={player.image}
									alt={`${player.firstName} ${player.lastName}`}
									className={`rounded-full border-solid ${isWinner ? 'border-2 border-green-300' : 'border border-white'} ${singles ? 'size-20 sm:size-[100px]' : 'size-12 sm:size-20'} `}
								/>
							) : (
								<div
									className={`flex items-center justify-center overflow-hidden rounded-full border-solid ${isWinner ? 'border-2 border-green-300' : 'border border-white'} ${singles ? 'size-20 sm:size-[100px]' : 'size-12 sm:size-20'}`}
								>
									<FontAwesomeIcon
										icon={faUser}
										className={`text-white ${singles ? 'size-[32px] sm:size-[56px]' : 'size-[24px] sm:size-[48px]'}`}
									/>
								</div>
							)}
							<div
								className={`line-clamp-2 flex items-center justify-center overflow-hidden text-ellipsis ${singles ? '' : 'flex-col'}`}
							>
								{singles ? (
									<>
										<span className={clsx('sm:hidden', nameClassesSingles)}>{singlesNamesTruncated}</span>
										<span className={clsx('hidden sm:flex', nameClassesSingles)}>{singlesNamesTruncatedSM}</span>
									</>
								) : (
									<>
										<span className={clsx('sm:hidden', nameClassesDoubles)}>{truncatedFirstName}</span>
										<span className={clsx('sm:hidden', nameClassesDoubles)}>{truncatedLastName}</span>
										<span className={clsx('hidden sm:flex', nameClassesDoubles)}>{truncatedFirstNameSM}</span>
										<span className={clsx('hidden sm:flex', nameClassesDoubles)}>{truncatedLastNameSM}</span>
									</>
								)}
							</div>
							{match.matchStatus === 2 && (
								<div className="flex gap-1">
									{playerOneIsServer && index === 0 && <div className="size-2 rounded-full bg-error-600" />}
									{playerOneIsServer && index === 0 && secondServerDot && <div className="size-2 rounded-full bg-error-600" />}
									{playerTwoIsServer && index === 1 && <div className="size-2 rounded-full bg-error-600" />}
									{playerTwoIsServer && index === 1 && secondServerDot && <div className="size-2 rounded-full bg-error-600" />}
								</div>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export interface MatchInfoProps {
	match: MatchV3;
}

const MatchInfo = ({ match }: MatchInfoProps) => {
	return (
		<>
			<div className="hidden w-full justify-center pb-2 sm:flex sm:pt-3">
				<span className="text-center text-xs font-medium leading-[18px] text-white">{match.eventTitle}</span>
				<span className="px-1 text-xs font-medium leading-[18px] text-white"> | </span>
				{match.roundNumber && (
					<>
						<span className="text-xs font-medium leading-[18px] text-white">{match.roundNumber}</span>
						<span className="px-1 text-xs font-medium leading-[18px] text-white"> | </span>
					</>
				)}
				<span className="text-xs font-medium leading-[18px] text-white">{match.matchTime}</span>
			</div>
			<div className="flex w-full flex-col items-center justify-center pt-2 sm:hidden">
				<span className="text-center text-xs font-medium leading-[18px] text-white">{match.eventTitle}</span>
				<div className="flex">
					{match.roundNumber && (
						<>
							<span className="text-xs font-medium leading-[18px] text-white">{match.roundNumber}</span>
							<span className="px-1 text-xs font-medium leading-[18px] text-white"> - </span>
						</>
					)}
					<span className="text-xs font-medium leading-[18px] text-white">{match.matchTime}</span>
				</div>
			</div>
		</>
	);
};

export interface MatchCardV3Props {
	match: MatchV3;
}

const MatchCardV3 = forwardRef<HTMLDivElement, MatchCardV3Props>(({ match }, _ref) => {
	const divRef = useRef(null);
	const singles = match['team1'].players.length === 1;
	const isCanceled = match.matchStatus === 4 && match.matchCompletedType === 6;
	const teamOneWithdrew = match.matchStatus === 4 && match.matchCompletedType === 4 && !match.team1.isWinner;
	const teamTwoWithdrew = match.matchStatus === 4 && match.matchCompletedType === 4 && !match.team2.isWinner;

	const scoresContainerClasses = clsx('flex flex-col gap-1', {
		'pt-10 sm:pt-14': isCanceled && singles,
		'pt-6 sm:pt-10': isCanceled && !singles,
		'pt-6 sm:pt-8': match.gamesStatus && match.gamesStatus.length === 0 && singles,
		'pt-1.5 sm:pt-5': match.gamesStatus && match.gamesStatus.length === 0 && !singles,
		'pt-2.5 sm:pt-5': match.gamesStatus && match.gamesStatus.length === 1 && singles,
		'sm:justify-center': match.gamesStatus && match.gamesStatus.length === 1 && !singles,
		'sm:pt-7 pt-9': singles && (teamOneWithdrew || teamTwoWithdrew),
		'sm:-mt-10 pt-6': !singles && (teamOneWithdrew || teamTwoWithdrew)
	});

	const shuttersStockUrl = '/shutterstock-2.png';

	return (
		<div className="w-full overflow-hidden rounded-md bg-[linear-gradient(90deg,#572BD6_31.91%,#0A0026_66.77%)] sm:rounded-lg" ref={divRef}>
			<div
				className="w-full bg-transparent"
				style={{
					backgroundImage: `url(${shuttersStockUrl})`,
					backgroundPosition: 'center top',
					backgroundSize: 'cover'
				}}
			>
				<CardHeader match={match} divRef={divRef} />
				{match.matchStatus === 2 && (
					<div className="grid grid-cols-[minmax(0,1fr)_auto_minmax(0,1fr)] px-3 pb-1 pt-2.5">
						<span className="truncate text-left"></span>
						<span className="bg-error-600 px-1.5 text-center text-xs font-semibold not-italic leading-[18px] text-white">Live</span>
						<span className="truncate pl-4 text-left text-xs font-semibold not-italic leading-[18px] text-white sm:hidden">
							{match.courtName}
						</span>
					</div>
				)}

				<div className="flex flex-row justify-evenly">
					<TeamInfo match={match} teamKey="team1" />
					<div className={scoresContainerClasses}>
						{isCanceled ? (
							<span className="text-center text-sm font-bold not-italic text-white sm:text-xl sm:leading-[30px]">Canceled</span>
						) : (
							<>
								<div
									className={`flex flex-col rounded-md ${match.matchStatus === 4 ? 'border border-solid border-green-300' : ''} sm:gap-1 sm:rounded-lg`}
								>
									{match.matchStatus === 4 && (
										<span className="text-md text-center font-medium not-italic leading-6 text-white">Final</span>
									)}
									<TotalScores match={match} />
								</div>
								{!teamOneWithdrew && !teamTwoWithdrew && <Scores match={match} />}
							</>
						)}
					</div>
					<TeamInfo match={match} teamKey="team2" />
				</div>
				<MatchInfo match={match} />
			</div>
		</div>
	);
});
MatchCardV3.displayName = 'MatchCardV3';

export default MatchCardV3;
